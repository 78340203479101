import React from 'react';
import './App.css';

import { LandingScene } from './scenes'

function App() {
  return (
    <LandingScene/>
  );
}

export default App;
