import {FC, useState} from "react";
import { MobileMenuButton } from './MobileMenuButton'
import { MobileMenu } from './MobileMenu'
import { NavLink } from './NavLink'
import { Logo } from './Logo'

const Nav: FC = () => {
  const [showMobileMenu, setShowMobileMenu]  = useState(false);

  
  return (
    <nav className="bg-black fixed w-full z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <MobileMenuButton toggle={() => setShowMobileMenu(!showMobileMenu)}/>
          <div className="flex">
            <Logo/>
            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              <NavLink to="#home" >Home</NavLink>
              <NavLink to="#signup">Signup</NavLink>
              <NavLink to="#learn">Learn</NavLink>
            </div>
          </div>
        </div>
      </div>
    
      <MobileMenu show={showMobileMenu}/>

    </nav>
    
  )
};

export default Nav;