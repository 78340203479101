import { FC } from "react";

import cx from 'classnames'


interface IProps {
    children:any;
    to: string;
    mobile?: boolean
    onClick?: ()=>void;
}


export const NavLink: FC<IProps> = (props:IProps) => {
    const { to, mobile } = props
    
    const styles = cx({
        'text-sm': !mobile,
        'text-base': mobile,
        'block': mobile
    })

    const onClick = () =>{if (props.onClick) {props.onClick()}}

    return(
        <a
            href={to}
            className={`
                text-gray-100
                hover:bg-gray-100
                hover:text-gray-900
                px-3
                py-2 
                rounded-md
                ${styles}
                font-medium"
            `}
            onClick={onClick}
            >{props.children}</a>
    )   
}

