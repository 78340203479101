import {FC} from "react";

interface IProps {
    toggle: () => void,
  }

export const MobileMenuButton: FC<IProps> = (props:IProps) => {
    return(
        <div className="-ml-2 mr-2 flex items-center md:hidden">
            {/* <!-- Mobile menu button --> */}
            <button 
                onClick={props.toggle}
                className="
                    inline-flex items-center justify-center
                    p-2 rounded-md text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false">
                {/* Icon when menu is closed. Heroicon name: menu Menu open: "hidden", Menu closed: "block" */}
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>

                {/* Icon when menu is open. Heroicon name: x Menu open: "block", Menu closed: "hidden" */}
                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
      </div>
    )
}
