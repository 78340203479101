import {FC} from "react";

import desktopLogo from '../../assets/g5d.png'
import mobileLogo from '../../assets/g5d_mobile.png'

export const Logo: FC = () => {
    return(
    <a className="flex-shrink-0 flex items-center" href="#home">
        <img className="block lg:hidden h-8 w-auto" src={mobileLogo} alt="Workflow"/>
        <img className="hidden lg:block h-8 w-auto" src={desktopLogo} alt="Workflow"/>
    </a>
    )
}
