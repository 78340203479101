import {FC, useState } from 'react'
import { Nav } from '../components'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

import validator from 'validator'

import guitarTattoo from '../assets/guitar_tattoo.jpg'

import gc from '../assets/gc_button.png'
import guitarTricks from '../assets/guitar_tricks_button.png'
import jamplay from '../assets/jamplay_button.png'


export const LandingScene:FC = () =>{

    const [msg, setMsg] = useState("")
    const [email, setEmail] = useState("")
    const [enabled, setEnabled] = useState(false)


    const handleEmailChange = (event:any) => {
        setEmail(event.target.value)
        if (validator.isEmail(event.target.value)) {
            setEnabled(true)
            setMsg("")
        } else {
            setEnabled(false)
            setMsg("Not a valid email.")
        }
    }

    const handleSubmit = () => {
        // const url = "https://guitar5day.us17.list-manage.com/subscribe/post?u=4f2a85a924bab82c9edcc5590&id=939be52afd"
        const url = "https://guitar-email-lambda.pejowei.vercel.app/"

        const config: AxiosRequestConfig = {
            data: {email,},
            method: "POST",
            url,
        }
        axios(config).then((response:AxiosResponse)=>{
            // const responseData = response.data.data
            setEmail("")
            setMsg("Check your email!")
        }).catch((error)=>(console.log(error)))


    }

    return(
        // {/* Start hero section */}
        <>
        <div id="home" className="relative bg-white overflow-hidden">
        <Nav/>
        <div className="max-w-7xl mx-auto">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">spacer</div>
            <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">

            </div>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">Pick up your guitar</span>
                </h1>
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">&</span>
                </h1>
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block text-red-700 xl:inline">LEARN TO PLAY</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Here we advocate learning to play guitar in ONE STEP:
                </p>
                <p className="mt-3 text-base text-red-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Pick it up every day.
                </p>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                After you are doing step one, you can explore other free lessons and great resources
                </p>
                
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                    <a
                        href="#signup"
                        className="
                            w-full flex items-center justify-center
                            px-8 py-3 border border-transparent
                            text-base font-medium rounded-md text-white
                            bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10">
                        Get started
                    </a>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                    <a  href="https://www.youtube.com/channel/UC0qdTvNIy63Mqp3Y7aOM8-g"
                        target="_blank"
                        referrerPolicy="no-referrer"
                        rel="noreferrer"
                        className="
                            w-full flex items-center justify-center px-8 py-3
                            border border-transparent
                            text-base font-medium rounded-md
                            text-red-700 bg-red-100form
                            hover:bg-red-200 md:py-4 md:text-lg md:px-10">
                        Watch Preview
                    </a>
                    </div>
                </div>

                </div>
            </main>
            </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={guitarTattoo} alt=""/>
        </div>
        </div>

        {/* start signup section */}
        <div className="bg-gray-100 py-40" id="signup">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl">
            Want to learn fast?
            </h2>
            <p className="inline text-3xl font-extrabold tracking-tight text-red-600 sm:block sm:text-4xl">Sign up for free practice cheat sheet.</p>
            <div className="mt-8 sm:flex">
            <label htmlFor="emailAddress" className="sr-only">Email address</label>
            <input 
                id="emailAddress"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                className="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md"
                onChange={handleEmailChange}
                placeholder="Enter your email"
                />
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button 
                    disabled={!enabled}
                    className="
                        w-full flex items-center justify-center
                        px-5 py-3 border border-transparent
                        text-base font-medium rounded-md text-white
                        bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={handleSubmit}
                >
                    Send it!
                </button>
            </div>
            </div>
            <p className="text-blue-600 mt-3">{msg}</p>
        </div>
        </div>

        {/* start affiliate section */}

        <div id="learn" className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                Learn to Play
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                Check out these great courses from our partners:
            </p>
            </div>
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <a 
                        href="http://www.jamplay.com/"
                        target="_blank"
                        rel="noreferrer"
                        referrerPolicy="no-referrer"
                        className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={jamplay} alt=""/>
                    </a>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-sm font-medium text-red-600">
                        <a href="http://www.jamplay.com/" target="_blank" rel="noreferrer" className="hover:underline">
                            Go to JamPlay!
                        </a>
                        </p>
                        <a href="http://www.jamplay.com/" target="_blank" rel="noreferrer" className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                        Helping thousands of guitarists like you reach new goals every day!


                        </p>
                        <p className="mt-3 text-base text-gray-500">
                        All skill levels from beginner to advanced.
                        Learn from world class instructors.
                        Learn songs, build skills, master styles.
                        Create your own learning path.
                        </p>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <a 
                        href="http://www.guitarcontrol.com/"
                        target="_blank"
                        referrerPolicy="no-referrer"
                        rel="noreferrer"
                        className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={gc} alt=""/>
                    </a>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-sm font-medium text-red-600">
                        <a href="http://www.guitarcontrol.com/" className="hover:underline">
                            Go to Guitar Control!
                        </a>
                        </p>
                        <a href="http://www.guitarcontrol.com/" target="_blank" rel="noreferrer" className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                            Learn guitar online from master musicians
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                        Premium online guitar courses since 2005.
                        </p>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <a 
                        href="https://www.guitartricks.com/?a_aid=583cb2d664a40"
                        target="_blank"
                        referrerPolicy="no-referrer"
                        rel="noreferrer"
                        className="flex-shrink-0">
                    <img className="h-48 w-full" src={guitarTricks} alt=""/>
                    </a>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <p className="text-sm font-medium text-red-600">
                        <a href="https://www.guitartricks.com/?a_aid=583cb2d664a40" className="hover:underline">
                            Go to Guitar Tricks!
                        </a>
                        </p>
                        <a href="https://www.guitartricks.com/?a_aid=583cb2d664a40" className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                            Award Winning Online Guitar Lessons!
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                            Picking up a guitar and making beautiful music is a great feeling and a big accomplishment. Sadly, most people believe they will never learn how to play. That’s why we started Guitar Tricks® – to provide online guitar lessons for those searching for an easy, fast, and fun way to learn guitar.                        </p>
                        </a>
                    </div>
                    </div>
                </div>

            

            
            </div>
        </div>
        </div>


        {/* end affiliate section */}

</>
        // end hero section

    )
}
