import {FC} from "react";
import cx from 'classnames'

import { NavLink } from './NavLink'

interface IProps {
    show: boolean,
  }

export const MobileMenu: FC<IProps> = (props:IProps) => {
    const classes = cx({
        "hidden": !props.show,
        "md:hidden": true,
    })

    return(
        <div className={`${classes}`}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <NavLink to="#home" mobile={true}>Home</NavLink>
                <NavLink to="#signup" mobile={true}>Signup</NavLink>
                <NavLink to="#learn" mobile={true}>Learn</NavLink>
            </div>
        </div>
    )
}


